import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { Companylist } from "./companylist";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  evolgame : any;
  isOpen: boolean;
  popupStatuses: string;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      gameCode: "",
      gameName: "",
      isOpen: false,
      evolgame : null,
      popupStatuses: popupView.none,
    };
  }

  componentDidMount() {
    // this.slotService.getSlotSetting().then((s) => {
    //   if (s.status === "success") {
    //     this.setState({ slots: s.slot, mode: Mode.slot });
    //   }
    // });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casionos: s.casiono, evolgame : s?.casiono.find((s : any) => s?.nameEn === 'Evolution') });
      }
    });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  };

  RenderSlot = (info: any, index : any) => {
    const imgUrl = info.mobileImg;
    
    if (index < 4) {
      return <></>;
    }


    return (
      <>
        <div className="box_wrap02 casino_1_open">
          <div className="box_box02">
            <a
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.setState({ popupStatuses: popupView.game });
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="box02">
                <img src={imgUrl} height="200" width="240" />
              </div>
              <div className="box022">{info.nameKo}</div>
            </a>
          </div>
        </div>
      </>
    );
  };

  RenderCasino = (info: any) => {
    const imgUrl = info.mobileImg;
    return (
      <>
        <div className="box_wrap02 casino_1_open">
          <div className="box_box02">
            <a
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="box02">
                <img src={imgUrl} height="200" width="240" />
              </div>
              <div className="box022">{info.nameKo}</div>
            </a>
          </div>
        </div>
      </>
    );
  };

  RenderSlot2 = (info: any, index: any) => {
    const imgUrl = info.mobileImg;

    if (index > 3) {
      return <></>;
    }


    return (
      <>
        <div className="box_wrap02 casino_1_open"  style={{width : '25%'}}>
          <div className="box_box02">
            <a
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.setState({ popupStatuses: popupView.game });
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="box02">
                <img src={imgUrl} />
              </div>
              <div className="box022">{info.nameKo}</div>
            </a>
          </div>
        </div>
      </>
    );
  };

  RenderGame = (info: any,  index : any) => {
    const imgUrl = info.mobileImg;
    

    return (
      <>
        <div className="box_wrap02 casino_1_open">
          <div className="box_box02">
            <a
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="box02">
                <img src={imgUrl} height="200" width="240" />
              </div>
              <div className="box022">{info.nameKo}</div>
            </a>
          </div>
        </div>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const BestCompany = () => {
      return (
        <>
          {this.state.slots.map((row: any, index: any) =>
            this.RenderSlot2(row, index)
          )}
        </>
      );
    };

    const RenderCompany = () => {
      if (this.state.mode === Mode.game) {
        return <>{this.state.games.map((row: any, index: any) => this.RenderGame(row, index))}</>;
      }

      return (
        <>
          {this.state.slots.map((row: any, index: any) => this.RenderSlot(row, index))}

        </>
      );
    };

    // 

    return (
      <>
        <div className="main_best_wrap">
          <div className="main_best_box">
            <div className="main_best_title">
              <img src="/images/evo_title.png" />
            </div>
            <div className="main_best">
              <a className="main_best1" 
                
                onClick={() => {
                  if (this.state?.evolgame?.used === "y") {
                    this.handleOpenSlot(this.state?.evolgame?.code, this.state?.evolgame?.gameCompany);
                  } else {
                    confirmAlert({
                      title: "점검중",
                      message: "현재 해당게임은 점검중입니다 .",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {},
                        },
                      ],
                    });
                  }
                }}
              >
                <img
                  src="/images/evo.png?v=1"
                  style={{ marginLeft: "20px", marginTop: "10px" }}
                />
                <img
                  src="/images/evo_hover.png"
                  className="mouseover2"
                  style={{
                    display: "none",
                    marginLeft: "20px",
                    marginTop: "10px",
                  }}
                />
              </a>
            </div>
            {/* <div className="main_best_title">
              <img src="/images/slot_title.png" />
            </div> */}
            {/* <div className="main_best">
              <ul>
                {BestCompany()}
              </ul>
            </div> */}
          </div>
        </div>

        <div className="all_wrap">
          {/* <div className="all_box">
            <div className="all_title">
              <img src="/images/all_title.png" />
            </div>
            <div className="con_box00">{RenderCompany()}</div>
          </div> */}

          <div className="all_box">
            <div className="all_title">
              <img src="/images/casino.png" />
            </div>
            <div className="con_box00">
            {this.state.casionos.map((row: any) => this.RenderCasino(row))}


            </div>
          </div>

        </div>
        {RenderPopup()}
      </>
    );
  }
}
