import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any) => {

    console.log(info)
    return (
      <li style={{ float: "left", paddingRight: "3px", paddingTop: "3px", padding : '10px' }}>
        <a
          href="javascript:;"
          onClick={() => {
            this.handleOpenSlot(info.code, info.gameCompany);
          }}
          style={{ textAlign: "center" }}
        >
          <img
            src={`${info.imgUrl}`}
            data-src={`${info.imgUrl}`}
            className="img-responsive"
            style={{ width: "180px", height: "180px" }}
          />
          <p style={{ overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', width : '140px', fontSize : '16px'
          }}>{info.nameKo}</p>
        </a>
      </li>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox" style={{ backgroundRepeat: "repeat" }}>
                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">{this.props.gameName}</div>
                    <div className="con_box10">
                      <ul className="gamecategory" id="slotList_a">
                        {this.state.games.map((row: any) =>
                          this.RenderGame(row)
                        )}
                      </ul>
                    </div>
                    <div className="con_box20">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.props.handleClose();
                              }}
                            >
                              <span className="btn3_1">닫기</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          //     <div>

          // <div className="modal fade show" id="board_notice-modal" aria-labelledby="board_notice-modalLabel" aria-modal="true" role="dialog" style={{display:'block'}}>
          //         <div className="modal-dialog modal-xl">
          //           <div className="modal-content">
          //             <div className="modal-header">
          //             <div className="modal-title-wrapper">
          //             <div className="modal-title-container">
          //               <h5 className="title-text">{this.props.gameName}</h5>
          //             </div>
          //           </div>
          //               <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {this.props.handleClose();}}>
          //                 <span aria-hidden="true"><img src="images/x-button.png"/></span>
          //               </button>
          //             </div>
          //             <div className="modal-body" style={{width:'100%',height:'500px',overflow:'auto'}}>

          //               {/* Content Start */}

          // 		<div className="gamelist" id="slotList" role="tabpanel">
          // 	  		<div className="tab-content">
          // 				<div role="tabpanel" className="tab-pane fade active show" id="wholegames">
          // 					<ul className="gamecategory" id="slotList_a">
          // 						{/* <li>
          // 							<a href="#">
          // 								<img src="https://resource.fdsigaming.com/thumbnail/slot/habanero/en/SGZeus.png" className="img-responsive"/>
          // 								<p>제우스</p>
          // 							</a>
          // 						</li> */}

          // 						{this.state.games.map((row: any) => this.RenderGame(row))}
          // 					</ul>
          // 		  		</div>
          // 			</div>
          // 		</div>

          //               {/* Content End */}

          //             </div>
          //           </div>
          //         </div>
          //       </div>

          //     </div>
        )}
      </Popup>
    );
  }
}
